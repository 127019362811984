import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A user with access to Engagement Studio;`}</li>
      <li parentName="ul">{`Google Ads campaign configured on Google's platform`}</li>
      <li parentName="ul">{`Campaign generating results and CoreMedia EC recording GCLID information`}</li>
      <li parentName="ul">{`Conversions created in your Google Ads account`}</li>
      <li parentName="ul">{`Workflows with defined rules and actions`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`Google Ads is a powerful tool that allows you to put your business in front of the right people at the right time. It's like having a digital billboard that you can control and optimize for the best results.`}</p>
    <h2>{`Steps`}</h2>
    <h3>{`1. Add Google Ads app`}</h3>
    <ol>
      <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Apps`}</em>{` on the menu.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add`}</em>{` to insert an app.`}</li>
      <li parentName="ol">{`Select `}<em parentName="li">{`App - Google Ads`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in the `}<em parentName="li">{`Name`}</em>{` and select `}<em parentName="li">{`Yes`}</em>{` to activate.`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required  and it is free text but you should give a meaningful name.`}</li>
          <li parentName="ul"><em parentName="li">{`Active`}</em>{`: `}<em parentName="li">{`Yes`}</em>{` or `}<em parentName="li">{`No`}</em></li>
        </ul>
      </li>
      <li parentName="ol">{`On the right bottom corner of form `}<em parentName="li">{`Create App`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`.`}</li>
      <li parentName="ol">{`When the app is created, a context is also created. Additional context can be added. Click on the `}<em parentName="li">{`Context name`}</em>{`. `}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add context`}</em>{`.`}</li>
      <li parentName="ol">{`Type a name and click `}<em parentName="li">{`Accept`}</em>{`.
10.The edit app is displayed. Proceed to `}<em parentName="li">{`Setttings`}</em>{`.`}</li>
      <li parentName="ol">{`Click on "Sign in with google".`}
        <br />![ec-edit-app-context](../images/ec-edit-app-context.png)
      </li>
    </ol>
    <h4>{`Result`}</h4>
    <p>{`A link that should be copied and sent to the client to be used on the campaigns.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`How to create a google event`}</strong></p>
      <ol parentName="blockquote">
        <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`Settings`}</em>{`.`}</li>
        <li parentName="ol">{`Click `}<em parentName="li">{`Global view`}</em>{` on the menu.`}</li>
        <li parentName="ol">{`Scroll to `}<em parentName="li">{`TAGs and names`}</em>{` and click `}<em parentName="li">{`Events management`}</em>{` on the menu.`}</li>
        <li parentName="ol">{`Click `}<em parentName="li">{`Add`}</em>{` to insert an Event.`}</li>
        <li parentName="ol">{`In `}<em parentName="li">{`Edit event`}</em>{`, fill in:`}</li>
      </ol>
      <ul parentName="blockquote">
        <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required and free text but you should give a meaningful name.`}</li>
        <li parentName="ul"><em parentName="li">{`Description`}</em>{`: It is free text.`}</li>
        <li parentName="ul"><em parentName="li">{`Event type`}</em>{`: Select `}<em parentName="li">{`Conversion`}</em>{`.`}</li>
        <li parentName="ul"><em parentName="li">{`Property name`}</em>{`: Add property.`}</li>
        <li parentName="ul"><em parentName="li">{`Title`}</em>{`: Add title`}</li>
        <li parentName="ul"><em parentName="li">{`Interpret value as`}</em>{`: Select `}<em parentName="li">{`Default`}</em>{`, `}<em parentName="li">{`Text`}</em>{`, `}<em parentName="li">{`Numeric`}</em>{` or `}<em parentName="li">{`Date`}</em>{`.`}</li>
        <li parentName="ul"><em parentName="li">{`Attribution model`}</em>{`: Select `}<em parentName="li">{`Last interaction`}</em>{`.`}</li>
        <li parentName="ul"><em parentName="li">{`Period`}</em>{`: Select `}<em parentName="li">{`Session_or _Days`}</em>{`.`}</li>
      </ul>
    </blockquote>
    <blockquote>
      <table parentName="blockquote">
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Property name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Title`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Interpret value as`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`convertion_gclid`}</em></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Google Click Id.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`Text`}</em></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`convertion_date`}</em></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Data da conversão.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`Text`}</em></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`convertion_name`}</em></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Nome da conversão.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`Text`}</em></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`callrequest_id`}</em></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`callrequest_id`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`Numeric`}</em></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`customer_phone`}</em></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`customer_phone`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`Numeric`}</em></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`contact_date`}</em></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`contact_date.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`Text`}</em></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`convertion_name`}</em></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Nome da conversão.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><em parentName="td">{`Text`}</em></td>
          </tr>
        </tbody>
      </table>
      <ol parentName="blockquote" {...{
        "start": 6
      }}>
        <li parentName="ol">{`Click `}<em parentName="li">{`Accept`}</em>{`.`}</li>
      </ol>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`How to Set Up Conversion Events in Google Adwords`}</strong></p>
      <ol parentName="blockquote">
        <li parentName="ol">{`Click on the tab `}<em parentName="li">{`Conversion Actions`}</em>{`.`}</li>
        <li parentName="ol">{`Click `}<em parentName="li">{`Actions syncronize`}</em>{`.`}</li>
        <li parentName="ol">{`Select one or more conversions and click on left hand blades.`}</li>
        <li parentName="ol">{`Click `}<em parentName="li">{`Accept.`}</em></li>
        <li parentName="ol">{`Click `}<em parentName="li">{`Close.`}</em>{`
Note: Refresh to see all conversions imported`}</li>
        <li parentName="ol">{`Move to `}<em parentName="li">{`Settings`}</em>{` tab. `}</li>
        <li parentName="ol">{`Click `}<em parentName="li">{`New Convertion Event`}</em>{`.`}</li>
        <li parentName="ol">{`Select the event on `}<em parentName="li">{`New conversion event`}</em>{`.`}</li>
        <li parentName="ol">{`On the right bottom corner of form `}<em parentName="li">{`Edit App`}</em>{`, click button `}<em parentName="li">{`Save`}</em>{`.`}</li>
      </ol>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      